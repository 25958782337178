/** @jsx jsx */
import { jsx } from "theme-ui"
import PropTypes from "prop-types"

import Background from "./background"
import { useStaticQuery, graphql } from "gatsby"
import styled from "@emotion/styled"
import Animate from "./animations"

function StyledVerticalLinesBackground({
  children,
  opacity,
  backgroundColor,
  className,
}) {
  const data = useStaticQuery(graphql`
    query {
      verticalImage: file(relativePath: { eq: "vertical-lines-4.png" }) {
        childImageSharp {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <div
      sx={{
        position: "relative",
        zIndex: 1,
        "::before": {
          content: '""',
          position: "absolute",
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          backgroundColor: backgroundColor,
          opacity: opacity,
        },
      }}
    >
      <Animate animationInDelay={200} animationIn="fadeInDownBig">
        <Background
          className={className}
          fluid={data?.verticalImage?.childImageSharp?.fluid}
        ></Background>
      </Animate>
      <div sx={{ position: "relative", zIndex: 2 }}>{children}</div>
    </div>
  )
}

StyledVerticalLinesBackground.defaultProps = {
  opacity: 0.5,
  backgroundColor: "#444",
}

StyledVerticalLinesBackground.propTypes = {
  opacity: PropTypes.number,
  backgroundColor: PropTypes.string,
}

const VerticalLinesBackground = styled(StyledVerticalLinesBackground)`
  position: absolute !important;
  z-index: 1;
  height: 100vh;
  max-height: 600px;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-position: center;
  background-repeat: repeat;
  background-size: auto;
`

export default VerticalLinesBackground
