/** @jsx  jsx */
import { jsx } from "theme-ui"
import BackgroundImage from "gatsby-background-image"

export default function Background({ as, children, fluid, ...props }) {
  return (
    <BackgroundImage {...props} Tag={as} fluid={fluid}>
      {children}
    </BackgroundImage>
  )
}
